import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function LockIcon({ className, height = '16', width = '14' }: Props) {
  return (
    <AccessibleIcon label="lock">
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 7V5C2 2.23858 4.23858 0 7 0C9.76142 0 12 2.23858 12 5V7C13.1046 7 14 7.89543 14 9V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V9C0 7.89543 0.895431 7 2 7ZM10 5V7H4V5C4 3.34315 5.34315 2 7 2C8.65685 2 10 3.34315 10 5Z"
          fill="currentColor"
        />
      </svg>
    </AccessibleIcon>
  );
}
