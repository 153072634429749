import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function RoseIcon({ className, height = '91', width = '91' }: Props) {
  return (
    <AccessibleIcon label="Rosarium Health">
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 24.077082 24.077084"
        version="1.1"
        id="svg260"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-96.956618,-128.26833)">
          <path
            className="cls-1"
            d="m 109.03574,140.34746 -2.70556,-2.70557 2.67851,-2.70065 1.99966,2.00704 1.42411,-1.42411 -3.42377,-3.43607 -4.11246,4.11984 -7.939612,-7.93961 v 17.30333 H 121.0214 V 128.3618 Z m -8.63567,3.20978 3.83452,-3.8419 -1.43887,-1.4069 -3.824685,3.82223 v -8.9997 l 10.064705,10.06471 4.10755,-4.10508 4.44696,4.46664 z m 18.61184,-1.43149 -4.44451,-4.45927 4.43959,-4.44204 z"
            fill="#eb4559"
            stroke="0.245961"
          />
          <polygon
            className="cls-1"
            points="0,84.61 37.01,84.61 37.01,97.89 46.11,97.89 46.11,75.51 0,75.51 "
            fill="#eb4559"
            transform="matrix(0.2459606,0,0,0.2459606,96.956618,128.26833)"
          />
          <polygon
            className="cls-1"
            points="51.74,97.89 60.84,97.89 60.84,84.61 97.84,84.61 97.84,75.51 51.74,75.51 "
            fill="#eb4559"
            transform="matrix(0.2459606,0,0,0.2459606,96.956618,128.26833)"
          />
        </g>
      </svg>
    </AccessibleIcon>
  );
}
