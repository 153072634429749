import { AccessibleIcon } from './AccessibleIcon';
import { IconProps } from '@radix-ui/react-icons/dist/types';
import { ArrowLeftIcon } from '@radix-ui/react-icons';

interface Props extends IconProps {
  label?: string;
}

export function LeftArrowIcon({ label, ...rest }: Props) {
  return (
    <AccessibleIcon label={label || 'left arrow'}>
      <ArrowLeftIcon {...rest} />
    </AccessibleIcon>
  );
}
