import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

export function TrashIcon({ className, height = '25', width = '25' }: Props) {
  return (
    <AccessibleIcon label="trash">
      <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.79357 6.2069H16.2074V17.931C16.2074 18.4797 15.9894 19.0058 15.6015 19.3941C15.2132 19.782 14.6871 20 14.1384 20H5.86254C5.31389 20 4.78774 19.782 4.39949 19.3941C4.01155 19.0058 3.79357 18.4797 3.79357 17.931V6.2069ZM12.7591 16.2069V10C12.7591 9.6193 12.4501 9.31034 12.0694 9.31034C11.6887 9.31034 11.3798 9.6193 11.3798 10V16.2069C11.3798 16.5876 11.6887 16.8966 12.0694 16.8966C12.4501 16.8966 12.7591 16.5876 12.7591 16.2069ZM8.62116 16.2069V10C8.62116 9.6193 8.3122 9.31034 7.93151 9.31034C7.55081 9.31034 7.24185 9.6193 7.24185 10V16.2069C7.24185 16.5876 7.55081 16.8966 7.93151 16.8966C8.3122 16.8966 8.62116 16.5876 8.62116 16.2069ZM18.2763 4.13793C18.2763 4.51894 17.9677 4.82759 17.5867 4.82759H2.41426C2.03326 4.82759 1.72461 4.51894 1.72461 4.13793C1.72461 3.77201 1.86978 3.42104 2.12871 3.1624C2.38733 2.90378 2.73801 2.75862 3.10392 2.75862H6.5522V2.06897C6.5522 0.926581 7.47846 0 8.62116 0H11.3798C12.5225 0 13.4487 0.926581 13.4487 2.06897V2.75862H16.897C17.2629 2.75862 17.6136 2.90379 17.8722 3.1624C18.1312 3.42102 18.2763 3.77202 18.2763 4.13793ZM12.0694 2.75862V2.06897C12.0694 1.68827 11.7608 1.37931 11.3798 1.37931H8.62116C8.24016 1.37931 7.93151 1.68827 7.93151 2.06897V2.75862H12.0694Z"
          fill="currentColor"
        />
      </svg>
    </AccessibleIcon>
  );
}
