import { AccessibleIcon } from './AccessibleIcon';

type Props = {
  className?: string;
};

export function AddCircledIcon({ className }: Props) {
  return (
    <AccessibleIcon label="add">
      <svg
        className={className}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.49351 0 0 4.49346 0 10C0 15.5065 4.49365 20 10 20C15.5064 20 20 15.5064 20 10C20 4.49364 15.5064 0 10 0ZM14.4155 10.7792H10.5194V14.4155C10.5194 14.7791 10.2337 15.0648 9.87008 15.0648C9.50646 15.0648 9.22073 14.7791 9.22073 14.4155V10.7792H5.58446C5.22084 10.7792 4.93511 10.4935 4.93511 10.1299C4.93511 9.76624 5.22084 9.48052 5.58446 9.48052H9.22073V5.58442C9.22073 5.22079 9.50646 4.93507 9.87008 4.93507C10.2337 4.93507 10.5194 5.22079 10.5194 5.58442V9.48052H14.4155C14.7792 9.48052 15.0649 9.76624 15.0649 10.1299C15.0649 10.4935 14.7792 10.7792 14.4155 10.7792Z"
          fill="currentColor"
        />
      </svg>
    </AccessibleIcon>
  );
}
