import * as Base from '@radix-ui/react-accessible-icon';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  label: string;
};

export const AccessibleIcon: React.FC<React.PropsWithChildren<Props>> = ({ children, label }) => (
  <Base.Root label={label}>{children}</Base.Root>
);
