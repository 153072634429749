import * as Prim from '@radix-ui/react-visually-hidden';
import React, { ReactNode } from 'react';

type Props = {
  children?: ReactNode | ReactNode[] | string;
};

export const VisuallyHidden: React.FC<React.PropsWithChildren<Props>> = ({ children }) => (
  <Prim.Root>{children}</Prim.Root>
);
