import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { VisuallyHidden } from './VisuallyHidden';

type Props = {
  className?: string;
  children?: ReactNode | ReactNode[];
};

export const Spinner: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => (
  <div className={classNames('loading-spinner', className)} role="alert" aria-live="assertive">
    <VisuallyHidden>{children || 'Loading...'}</VisuallyHidden>
  </div>
);
