import React, { ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import { SelectField } from '../../types/Field';
import { Select } from './Select';

type StandardSelectFormFieldProps = {
  className?: string;
  // override label or null to disable the automatic field label
  label?: ReactNode | ReactNode[];
  selectClassName?: string;
  selectedOption?: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  field: SelectField;
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
};

export const StandardSelectFormField = observer(
  ({
    className,
    label,
    selectClassName,
    selectedOption = '',
    onChange,
    field,
    disabled,
    invalid = false,
    required,
  }: StandardSelectFormFieldProps) => {
    const isInvalid = field.isInvalid() || invalid;

    const body = (
      <Select
        id={field.id}
        className={selectClassName}
        name={field.name}
        onChange={onChange}
        options={field.options}
        value={field.value || selectedOption}
        disabled={disabled}
        required={required}
        invalid={isInvalid}
        error={field.error}
      />
    );

    if ((!field.label && !label) || label === null) return body;

    return (
      <label className={className} htmlFor={field.id}>
        {label && <span className="input-label-text">{label}</span>}
        {!label && <span className="input-label-text">{field.label}</span>}
        {field.smallText && <small>{field.smallText}</small>}
        {body}
      </label>
    );
  }
);

StandardSelectFormField.displayName = 'StandardSelectFormField';
