import { AccessibleIcon } from './AccessibleIcon';
import { IconProps } from '@radix-ui/react-icons/dist/types';
import { ArrowRightIcon } from '@radix-ui/react-icons';

interface Props extends IconProps {
  label?: string;
}

export function RightArrowIcon({ label, ...rest }: Props) {
  return (
    <AccessibleIcon label={label || 'right arrow'}>
      <ArrowRightIcon {...rest} />
    </AccessibleIcon>
  );
}
