/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ClassAttributes, InputHTMLAttributes } from 'react';
import InputError from './InputError';
import classNames from 'classnames';
import { ISelectOption } from '~/model/helper';

type InputSuper = ClassAttributes<HTMLSelectElement> & InputHTMLAttributes<HTMLSelectElement>;

export interface Props extends InputSuper {
  label?: string;
  error?: string;
  invalid?: boolean;
  options: ISelectOption[];
}

export const Select: React.FC<Props> = ({
  id,
  className,
  label,
  error,
  invalid,
  options,
  ...rest
}) => {
  const isInvalid = invalid || Boolean(error);
  const errorId = `${id}-error`;
  const selectField = (
    <>
      <select
        id={id}
        className={classNames(className, isInvalid && 'invalid')}
        aria-invalid={isInvalid}
        {...rest}
      >
        <option value="">Select one</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label || option.value}
          </option>
        ))}
      </select>
      {error && <InputError id={errorId} error={error} />}
    </>
  );

  if (label) {
    return (
      <label>
        <span className="input-label-text">{label}</span>
        {selectField}
      </label>
    );
  }

  return selectField;
};
