import React, { ChangeEvent, RefAttributes } from 'react';
import * as CheckboxBase from '@radix-ui/react-checkbox';
import { CheckboxProps as PrimitiveProps, CheckedState } from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';

type SuperProps = PrimitiveProps & RefAttributes<HTMLButtonElement>;

export interface CheckboxProps extends Omit<SuperProps, 'onChange'> {
  // @deprecated use onCheckedChange
  onToggle?: (event: ChangeEvent<HTMLInputElement>) => void;
  // you should probably use
  onCheckedChange?(checked: CheckedState): void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ className, onToggle, ...rest }) => (
  <CheckboxBase.Root className={classNames('checkbox', className)} {...rest}>
    <CheckboxBase.Indicator onChange={onToggle} className="indicator">
      <CheckIcon height={undefined} width={undefined} />
    </CheckboxBase.Indicator>
  </CheckboxBase.Root>
);
