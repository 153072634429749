import React, { ReactNode } from 'react';
import classNames from 'classnames';

type Props = {
  /**
   * needs to have an id and the field could optionally have `aria-describedby=id`
   * but my screen reader seems to work without it
   */
  id?: string;

  /**
   * the error message
   */
  error?: ReactNode;

  className?: string;
};

export default function InputError({ className, id, error }: Props) {
  if (!error) return null;

  return (
    <div id={id} className={classNames('error-message', className)} aria-live="assertive">
      {error}
    </div>
  );
}
