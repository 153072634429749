/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ClassAttributes, InputHTMLAttributes } from 'react';
import InputError from './InputError';
import classNames from 'classnames';

type InputSuper = ClassAttributes<HTMLInputElement> & InputHTMLAttributes<HTMLInputElement>;

export interface InputProps extends InputSuper {
  label?: string;
  error?: string;
  invalid?: boolean;
}

export const Input: React.FC<InputProps> = ({ id, className, label, error, invalid, ...rest }) => {
  const isInvalid = invalid || Boolean(error);
  const inputField = (
    <input
      id={id}
      className={classNames(className, isInvalid && 'invalid')}
      aria-invalid={isInvalid}
      {...rest}
    />
  );

  const errorId = `${id}-error`;
  if (label) {
    return (
      <label>
        <span className="input-label-text">{label}</span>
        {inputField}
        <InputError id={errorId} error={error} />
      </label>
    );
  }

  return inputField;
};
